import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"
import ContentBlock from '../components/ContentBlock/ContentBlock'
import PageTitle from '../components/PageTitle/PageTitle'
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const School = ({ data }) => {
  console.log(data);

  const options = {
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: ({data: { target: { fields }}}) => 
            <div
            dangerouslySetInnerHTML={{
              __html: `<div">
              <img src="${fields.file["en-US"].url}" alt="${fields.title["en-US"]}" class="img-fluid" style="margin: 0px" />
              <p>
                <em>${fields.title["en-US"]}</em>
              </p>
              </div>`,
            }}
          />,
  
            [BLOCKS.EMBEDDED_ENTRY]: (node) => {
                const fields = node.data.target.fields.imageLink;
                const imgLnk = node.data.target.fields.url;
                console.log(fields, imgLnk)
                    return (
                    <div dangerouslySetInnerHTML={{__html: `<a href="${imgLnk['en-US']}"><img class="img-fluid" src="${fields['en-US'].fields.file['en-US'].url}" alt="${fields.title}" style="width: 100%" /></a>` }} />
                    )
                },
  
                [INLINES.EMBEDDED_ENTRY]: (node) => {
                  const embed = node.data.target.fields.markdownContent;
                  console.log('embed data: ' + embed['en-US'])
                  return (
                      <div dangerouslySetInnerHTML={{__html: `${embed['en-US']}`}} />
                  )
              },
              [INLINES.HYPERLINK]: (node) => {
                return <a href={node.data.uri} target={`${node.data.uri.startsWith('https://uss-idaho.inlightmedia.io/') ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith('https://uss-idaho.inlightmedia.io/') ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
              }
  
    }
  }

  return (

    <Layout>
    <SEO title={data.contentfulSchoolOfTheBoat.title} />
    <Container fluid className="container-fluid-full">
      <PageTitle pageTitle={data.contentfulSchoolOfTheBoat.title} >
        {/* {page.pageTitle} */}
      </PageTitle>
      <ContentBlock>
       <div>
       {documentToReactComponents(data.contentfulSchoolOfTheBoat.content.json,options)}
      </div>
</ContentBlock>
</Container>
  </Layout>

  
  )
}

export const getBlogPost = graphql`
query SchoolOfTheBoat($slug: String!) {
  contentfulSchoolOfTheBoat(slug: {eq: $slug}) {
    title
    slug
    featuredImage {
      fluid {
        srcSetWebp
      }
    }
    content {
      json
    }
    excerpt {
      excerpt
    }
  }
}
`

export default School;
