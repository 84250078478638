import React from "react"
import { graphql } from "gatsby"
import SEO from '../components/seo'
import Layout from '../components/layout'
import ContentBlock from '../components/ContentBlock/ContentBlock'
import { Row, Col, CardDeck, Card } from 'react-bootstrap'
import PageTitle from '../components/PageTitle/PageTitle'
import { Container } from 'react-bootstrap'
import SchoolCard from '../components/SchoolCard/SchoolCard'
import School from "../templates/school-page-template"

const SchoolOfTheBoat = ({ data }) => {
  
  const pages = data.allContentfulSchoolOfTheBoat.edges
  console.log(pages)

  return (

<Layout>
<SEO title="USS IDAHO School Of The Boat"/>
<Container fluid className="container-fluid-full">
  <PageTitle pageTitle="School Of The Boat" >
  </PageTitle>
  <ContentBlock>
    <CardDeck className="text-center">
    
      {pages.map((item, index ) => (
        <SchoolCard key={index} data={item} />
      ))}
</CardDeck>
   </ContentBlock>
    </Container>
</Layout>
  )
}
export const query = graphql`
query AllSchoolOfTheBoat {
  allContentfulSchoolOfTheBoat(sort: {fields: slug, order: ASC}) {
    edges {
      node {
        featuredImage {
          fluid(cropFocus: CENTER, resizingBehavior: CROP) {
            srcSet
            aspectRatio
          }
        }
        slug
        title
        excerpt {
          excerpt
        }
      }
    }
  }
}
`

export default SchoolOfTheBoat;
