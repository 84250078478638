import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import * as styles from './homepage.module.css'
import { CardDeck, Card, Button } from 'react-bootstrap'
import IdahoNavalHistory from '../../images/naval-history.jpg'
import GemOfTheFleet from '../../images/gem-of-the-fleet.jpg'
import SealsFrontpage from '../../images/seals-frontpage.jpg'

const SchoolCards = ({ data }) => {
    console.log(data)

    const FeaturedImage = () => {
        return (
            <Img fluid={data.node.featuredImage.fluid} alt={data.node.title} style={{borderTopRightRadius: 'calc(.25rem - 1px)', borderTopLeftRadius: 'calc(.25rem - 1px)'}}/>
        )
    }

return (
    
        <div style={{textAlign: 'center'}} className={styles.schoolCard}>
            <Card className={styles.cardBox} >
                <FeaturedImage />
            {/* <Card.Img variant="top" src={FeaturedImage} /> */}
              <Card.Body>
                <Card.Title>
                  {data.node.title}
                </Card.Title>
                <Card.Text>
                {data.node.excerpt.excerpt}
                </Card.Text>
              </Card.Body>
              <Card.Footer className={styles.cardBoxFooter}>
                <Button className="ussIdahoButton">
                    <Link to={`/school-of-the-boat/${data.node.slug}`}>Learn More</Link>
                </Button>
                </Card.Footer> 
            </Card>
        </div>
    )
}

export default SchoolCards